import React from 'react';
import { Link } from '@reach/router';
import { currentYear } from '../../utils';
import './index.css';

export default ({ headerTheme = {} }) => {
  const { backgroundColor = 'none' } = headerTheme;

  return (
    <div className="footer-wrap" style={{ backgroundColor: backgroundColor }}>
      <div className="footer">
        <nav className="nav-list">
          <a
            // className='link'
            // to="/feedback"
            href="mailto:customerservice@yuzeno.com"
          >
            Feedback
          </a>
          <Link className="link" to="/help">
            Help
          </Link>
          <Link className="link" to="/about">
            About us
          </Link>
          <Link className="link" to="/terms">
            Terms of Service
          </Link>
          <Link className="link" to="/privacy">
            Privacy Policy
          </Link>
        </nav>
        <div className="action-list">&copy; {currentYear} yuzeno.com All Right Reserved</div>
      </div>
    </div>
  );
};
