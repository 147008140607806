import React, { Fragment } from 'react';
import { Router, Redirect } from '@reach/router';
import loadable from '@loadable/component';
import Layout from './components/Layout';
import { AuthorizedRoute } from './components/Authorized';
import Loading from './components/Loading';
import './App.scss';

const Home = loadable(() => import('./pages/home')); // Home
const Signin = loadable(() => import('./pages/signin')); // Sign In
const Signup = loadable(() => import('./pages/signup')); // Sign Up
const Forgot = loadable(() => import('./pages/forgot')); // Forgot
const Search = loadable(() => import('./pages/search')); // Search

const Teach = loadable(() => import('./pages/teach')); // Teach
const AddQuestion = loadable(() => import('./pages/teach/AddQuestion'));
const EditQuestion = loadable(() => import('./pages/teach/EditQuestion'));

const Learn = loadable(() => import('./pages/learn')); // Learn
const CategoryList = loadable(() => import('./pages/learn/categoryList'));
const StudyLog = loadable(() => import('./pages/learn/studyLog'));
const StarredCategory = loadable(() => import('./pages/learn/starredCategory'));
const Category = loadable(() => import('./pages/learn/category'));
const SubCategory = loadable(() => import('./pages/learn/subCategory'));
const Question = loadable(() => import('./pages/learn/question'));

const UserCenter = loadable(() => import('./pages/user')); // UserCenter
const Activity = loadable(() => import('./pages/user/Activity'));
const Stars = loadable(() => import('./pages/user/Stars'));
const Connection = loadable(() => import('./pages/user/Connection'));

const Settings = loadable(() => import('./pages/settings')); // Setting
const Profile = loadable(() => import('./pages/settings/profile'));
const Security = loadable(() => import('./pages/settings/security'));

//organization
const OrganizationList = loadable(() => import('./pages/organization'));
const AddOrganization = loadable(() => import('./pages/organization/AddOrganization'));
const Organization = loadable(() => import('./pages/organization/Organization'));

const Feedback = loadable(() => import('./pages/feedback')); // Feedback
const About = loadable(() => import('./pages/about')); // About
const Terms = loadable(() => import('./pages/terms')); // Terms
const Privacy = loadable(() => import('./pages/privacy')); // Privacy

const RegisteredSuccessfully = loadable(() => import('./pages/signup/registeredSuccessfully'));
const VerificationSucceed = loadable(() => import('./pages/signup/verificationSucceed'));
const VerificationFailed = loadable(() => import('./pages/signup/verificationFailed'));

const ForgotEmailSuccessfully = loadable(() => import('./pages/forgot/forgotEmailSuccessfully')); // Forgot successfully
const Reset = loadable(() => import('./pages/forgot/reset')); // Reset Forgot
const ForgotFailed = loadable(() => import('./pages/forgot/forgotFailed')); // Forfot failed
const ResetSuccessfully = loadable(() => import('./pages/forgot/resetSuccessfully'));
// website help
const Help = loadable(() => import('./pages/help'));
// app supports
const Supports = loadable(() => import('./pages/supports'));

const App = () => (
  <Fragment>
    <Router>
      <Home path="/" fallback={<Loading />} />

      <Layout path="/">
        <Search path="/search" fallback={<Loading />} />

        <Signin path="/signin" fallback={<Loading />} />
        <Signup path="/signup" fallback={<Loading />} />
        <Forgot path="/forgot" fallback={<Loading />} />
        <Reset path="/reset" fallback={<Loading />} />

        <RegisteredSuccessfully path="/registeredSuccessfully" fallback={<Loading />} />
        <VerificationSucceed path="/verificationSucceed" fallback={<Loading />} />
        <VerificationFailed path="/verificationFailed" fallback={<Loading />} />
        <ForgotEmailSuccessfully path="/forgotEmailSuccessfully" fallback={<Loading />} />
        <ResetSuccessfully path="/resetSuccessfully" fallback={<Loading />} />
        <ForgotFailed path="/forgotFailed" fallback={<Loading />} />

        <AuthorizedRoute path="/teach" noMatch={<Redirect from="/teach" to="/signin" />}>
          <Teach path="/" fallback={<Loading />} />
          <AddQuestion path="/create" fallback={<Loading />} />
          <EditQuestion path="/edit/:questionId" fallback={<Loading />} />
        </AuthorizedRoute>

        {/* organization */}
        <AuthorizedRoute path="/organization" noMatch={<Redirect from="/organization" to="/signin" />}>
          <OrganizationList path="/" fallback={<Loading />} />
          <AddOrganization path="/add" fallback={<Loading />} />
          <AddOrganization path="/edit/:id" fallback={<Loading />} />
          <Organization path="/:id" fallback={<Loading />} />
        </AuthorizedRoute>

        <Learn path="/learn">
          <Redirect from="/learn" to="/" />
          <CategoryList path="/" fallback={<Loading />} />
          <StudyLog path="/studyLog" fallback={<Loading />} />
          <StarredCategory path="/stars" fallback={<Loading />} />
          <Category path="/:category" />
          <SubCategory path={'/:categoryId/:subcategoryId'} fallback={<Loading />} />
        </Learn>

        <Question path="/learn/question/:questionId" fallback={<Loading />} />

        <UserCenter path="user/:userId">
          <Activity path="/activity" fallback={<Loading />} />
          <Stars path="/stars" fallback={<Loading />} />
          <Connection path="/connection" fallback={<Loading />} />
        </UserCenter>

        <AuthorizedRoute path="/settings" noMatch={<Redirect from="/settings" to="/signin" />}>
          <Settings path="/">
            <Redirect from="/" to="/settings/profile" />
            <Profile path="/profile" fallback={<Loading />} />
            <Security path="/security" fallback={<Loading />} />
          </Settings>
        </AuthorizedRoute>

        <Feedback path="/feedback" fallback={<Loading />} />
        <About path="/about" fallback={<Loading />} />
        <Terms path="/terms" fallback={<Loading />} />
        <Privacy path="/privacy" fallback={<Loading />} />
        <Help path="/help" fallback={<Loading />} />
      </Layout>

      <Supports path="/supports" fallback={<Loading />} />
    </Router>
  </Fragment>
);

export default App;
