import request from '../utils/request';
import queryString from 'query-string';

/**
 * 获取组织列表
 * @param {*} payload
 * @returns
 */
export function getOrganizationListService(payload = {}) {;
  const url = `/api/organization/user/${payload.userId}?pageNo=1&pageSize=20`;
  return request(url, {
    type: 'get',
  });
}

/**
 * 获取组织详情
 * @param {*} param0
 */
export function getOrganizationService({id}) {
  const url = `/api/organization/info/${id}`;
  return request(url, {
    type: 'get',
  });
}

/**
 * 获取组织问题
 * @param {*} param0
 * @returns
 */
export function getOrganizationQuestionsService({id}) {
  const url = `/api/organization/articles/${id}`;
  return request(url, {
    type: 'get',
  });
}

/**
 * 获取组织成员
 * @param {*} param0
 * @returns
 */
export function getOrganizationMembersService({id}) {
  const url = `/api/organization/users/${id}`;
  return request(url, {
    type: 'get',
  });
}

/**
 * 创建组织
 * @param {*} payload
 * @returns
 */
export function createOrganizationService(payload) {
  const url = `/api/organization/create`;
  return request(url, {
    type: 'post',
    data: payload
  });
}

/**
 * 编辑组织
 * @param {*} payload
 * @returns
 */
 export function upgradeOrganizationService(payload) {
  const url = `/api/organization/update`;
  return request(url, {
    type: 'put',
    data: payload
  });
}

/**
 * 邀请用户加入组织
 * @param {*} payload
 * @returns
 */
export function inviteService(payload) {
  const url = `/api/organization/invit`;
  return request(url, {
    type: 'post',
    data: payload
  });
}

/**
 * 用户接受/忽略组织邀请
 * @param {*} payload
 * @returns
 */
export function joinOrganizationService(payload) {
  const url = `/api/organization/join`;
  return request(url, {
    type: 'post',
    data: payload
  });
}

/**
 * 设置组织成员角色
 * @param {*} payload
 * @returns
 */
export function setUserRoleService(payload) {
  const url = `/api/organization/uprole`;
  return request(url, {
    type: 'post',
    data: payload
  });
}

/**
 * 组件创建者删除组织
 * @param {*} param0
 */
export function deleteOrganizationService({id}) {
  const url = `/api/organization/delete/${id}`;
  return request(url, {
    type: 'delete',
  }, true);
}

/**
 * 用户退出组织
 * @param {*} param0
 * @returns
 */
export function quitOrganizationService({id}) {
  const url = ``;
  return request(url, {

  });
}