const initialState = {
  token: null,
  userInfo: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `user/updateState`:
      return Object.assign({}, state, {
        ...payload,
      });
    default:
      return state;
  }
};
