const initialState = {
  categories: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `category/updateState`:
      return Object.assign({}, state, {
        ...payload,
      });
    default:
      return state;
  }
};
