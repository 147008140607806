import React from 'react';
import Header from '../header';
import Footer from '../Footer';

export default ({ children }) => {
  return (
    <div className="page-wrap">
      <Header />
      <div className="page-container">{children}</div>
      <Footer />
    </div>
  );
};
