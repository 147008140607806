import request from '../utils/request';
import queryString from 'query-string';

/**
 * 登录
 * @param payload
 * @returns {Promise<*>}
 */
export function signinService(payload = {}) {
  const url = '/api/signin?loginType=username';
  return request(url, {
    type: 'post',
    data: payload,
  });
}

/**
 * 登出
 * @returns {Promise<*>}
 */
export function signoutService() {
  const url = '/api/signout';
  return request(url, {
    type: 'post',
  });
}

/**
 * 注册
 * @param payload
 * @returns {Promise<*>}
 */
export function signupService(payload = {}) {
  const url = '/api/signup';
  return request(url, {
    type: 'post',
    data: payload,
  });
}

export function getActivitiesService(id, query) {
  const q = queryString.stringify(query);
  const url = `/api/audit/${id}?${q}`;
  return request(url);
}

export function forgotService(payload) {
  const url = '/api/reset/sendmail';
  return request(url, {
    type: 'post',
    data: payload,
  });
}

export function resetService({ userId, secret, password }) {
  const url = `/api/reset/password/${userId}/${secret}`;
  return request(url, {
    type: 'post',
    data: { password },
  });
}

export function updateUserInfo(payload) {
  const url = '/api/user/update';
  return request(url, {
    type: 'put',
    data: payload,
  });
}

export function getUserInfoService(userId) {
  const url = userId ? `/api/user/info/public/${userId}` : '/api/user/info';
  return request(url);
}

export function getUserFavorite(userId, type) {
  const url = `/api/collects/withsource/${userId}?type=${type}`;
  return request(url);
}

/**
 * 获取联系人列表
 * @param userId
 * @param type
 * @param pageNo
 * @param pageSize
 * @returns {Promise<*>}
 */
export function getUserConnectionService(userId, type, { pageNo, pageSize }) {
  const url = `/api/follower/user/${userId}?type=${type}&pageNo=${pageNo}&pageSize=${pageSize}`;
  return request(url, {
    type: 'post',
  });
}

export function followService(payload) {
  const url = '/api/follower/create';
  return request(url, {
    type: 'post',
    data: payload,
  });
}

/**
 * 更新密码
 */
export function updatePasswordService(payload) {
  const url = '/api/user/password/update';
  return request(url, {
    type: 'post',
    data: payload,
  });
}

export function relationshipService(id) {
  const url = `/api/follower/user/${id}`;
  return request(url, {}, false);
}

/**
 * 查询用户的邀请
 */
export function getUserInviteService() {
  const url = `/api/organization/invit`;
  return request(url, {type: 'get'}, false);
}