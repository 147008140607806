import React from 'react';
import { Link } from '@reach/router';

const isActive = ({ isCurrent }, className) => {
  return { className: isCurrent ? `active ${className}` : className };
};

const ExactNavLink = (props) => <Link getProps={(route) => isActive(route, props.className)} {...props} />;

export default ExactNavLink;
