import React, { useCallback } from 'react';
import { notification } from 'antd';
import { Link } from '@reach/router';
import { useMappedState } from 'redux-react-hook';

const tip = (
  <div>
    Please <Link to="/signin">Sign in</Link>, before to do this.
  </div>
);

const AuthorizedButton = ({ children }) => {
  const authorized = useMappedState(useCallback(({ user }) => !!user.token, []));
  const childrenRender = typeof children === 'undefined' ? null : children;
  const { onClick, ...others } = childrenRender.props;
  const props = {
    onClick: () => {
      notification.warning({
        message: 'Need Sign in',
        description: tip,
        onClick: () => {
          notification.destroy();
        },
      });
    },
  };
  if (authorized) {
    return <>{childrenRender}</>;
  } else {
    return <childrenRender.type {...others} {...props} />;
  }
};

export default AuthorizedButton;
