/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { List, Avatar, Skeleton, notification } from 'antd';
// import { useGetSet } from 'react-use';
import { joinOrganizationService } from '../../services/organization';

const Message = (props) => {
  const {data, refresh} = props;

  // 点击接受
  const doAccept = async (id) => {
    await joinOrganizationService({
      id: id,
      status: 1,
    });
    notification.success({
      message: 'Success',
      description: 'Join the Organization success.',
    });
    refresh();
  };

  // 点击忽略
  const doIgnore = async (id) => {
    await joinOrganizationService({
      id: id,
      status: 2,
    });
    notification.success({
      message: 'Success',
      description: 'Ignore the Organization success.',
    });
    refresh();
  };

  return (
    <div style={{width: 420}}>
    <List
      className="demo-loadmore-list"
      loading={data.initLoading}
      itemLayout="horizontal"
      dataSource={data.value || []}
      renderItem={item => (
        <List.Item
          actions={[
            <a key="list-accept" onClick={() => doAccept(item.id)}>Accept</a>,
            <a key="list-ignore" onClick={() => doIgnore(item.id)}>Ignore</a>
          ]}
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              // avatar={
              //   <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              // }
              title={`${item.beVisitName} invite you join organization ${item.organizationName}`}
            />
          </Skeleton>
        </List.Item>
      )}
    />
    </div>
  );
};

export default Message;
