import React, { useCallback, Fragment } from 'react';
import { Menu, Avatar, Dropdown, Icon, Badge, Popover } from 'antd';
import logo from '../../assets/logo.png';
import { Link, navigate } from '@reach/router';
import ExactNavLink from '../ExactNavLink';
import './index.css';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { getUserInviteService, signoutService } from '../../services/user';
import Message from './Message';
import { useAsyncFn, useMount } from 'react-use';

export default ({ headerTheme = {}, isHome }) => {
  const { backgroundColor } = headerTheme;

  const dispatch = useDispatch();

  const { userInfo } = useMappedState(useCallback((state) => state.user, []));

  const handleSignOut = async () => {
    return await signoutService();
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      // remove state from store
      dispatch({
        type: 'user/updateState',
        payload: {
          token: null,
          userInfo: null,
        },
      });
      // remove state from localStorage
      localStorage.clear();
      // sign out
      handleSignOut();
      // redirect to index`
      navigate('/');
    } else {
      navigate(key);
    }
  };

  const menuHeaderDropdown = (userId) => (
    <Menu selectedKeys={[]} onClick={(e) => handleMenuClick(e)}>
      <Menu.Item key={`/user/${userId}/activity`}>
        <Icon type="user" />
        <span>User center</span>
      </Menu.Item>
      <Menu.Item key="/settings">
        <Icon type="setting" />
        <span>Settings</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <Icon type="logout" />
        <span>Sign out</span>
      </Menu.Item>
    </Menu>
  );

  const [userInvite, getUserInvite] = useAsyncFn(async () => {
    const {infos} = await getUserInviteService();
    return infos;
  });

  useMount(() => {
    getUserInvite();
  });

  return (
    <div className="header-wrap" style={{ backgroundColor: backgroundColor }}>
      <div className="header">
        {!isHome && (
          <div id="logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
        )}
        {!isHome && (
          <nav className="nav-list">
            <ExactNavLink className="link" to="/">
              Home
            </ExactNavLink>
            <ExactNavLink className="link" to="/teach">
              Teach
            </ExactNavLink>
            <ExactNavLink className="link" to="/learn">
              Learn
            </ExactNavLink>
            <ExactNavLink className="link" to="/organization">
              Organization
            </ExactNavLink>
          </nav>
        )}
        <div className="action-list">
          {userInfo ? (
            <div className="user-info">
              <span className="alert">
                <Popover placement="bottom" content={<Message data={userInvite} refresh={getUserInvite}/>}>
                  <Badge count={userInvite.value && userInvite.value.length} offset={[0, -2]}>
                    <Icon type="bell" style={{fontSize: 20}} />
                  </Badge>
                </Popover>
              </span>
              <Dropdown size={'large'} overlay={menuHeaderDropdown(userInfo.id)} overlayStyle={{ paddingTop: 12 }}>
                <span className="basic">
                  <Avatar className="avatar" icon={'user'} src={userInfo.avatarURL} />
                  <span className="name">{userInfo.name}</span>
                  <Icon className="icon" type="caret-down" />
                </span>
              </Dropdown>
            </div>
          ) : (
            <Fragment>
              <Link className="link" to="/signin">
                Sign in
              </Link>
              <Link className="link" to="/signup">
                Sign up
              </Link>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
