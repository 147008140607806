import { createStore } from 'redux';
import reducer from './reducer';

const token = localStorage.getItem('token');
const user = localStorage.getItem('user');

const INITIAL_STATE = {
  user: {
    token,
    userInfo: JSON.parse(user),
  },
};

export function makeStore() {
  return createStore(reducer, INITIAL_STATE);
}
