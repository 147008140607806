import React from 'react';
import { Spin } from 'antd';
import './index.css';

export default () => {
  return (
    <div className="loading-wrap">
      <div className="loading-box">
        <Spin size="large" />
      </div>
    </div>
  );
};
