import axios from 'axios';
import { notification } from 'antd';
import React from 'react';
import { navigate, Link } from '@reach/router';

// 允许携带cookie
axios.defaults.withCredentials = true;

const codeMessage = {
  200: 'Standard response for successful HTTP requests.',
  201: 'The request has been fulfilled, resulting in the creation of a new resource.',
  202: 'The request has been accepted for processing, but the processing has not been completed.',
  204: 'The server successfully processed the request and is not returning any content.',
  400: 'Invalid request message, please check.',
  401: 'Authentication is required, please sign in.',
  403: '403 Forbidden.',
  404: '404 not Found.',
  406: 'Not Acceptable.',
  410: 'Indicates that the resource requested is no longer available and will not be available again.',
  422: 'The request was well-formed but was unable to be followed due to semantic errors.',
  500: 'An error has occurred on the server. Please check the server.',
  502: 'Bad Gateway.',
  503: 'The server cannot handle the request (because it is overloaded or down for maintenance). ',
  504: 'Gateway Timeout.',
};

async function request(url, options = {}, handleError = true) {
  const { type = 'get', data } = options;
  const promise = new Promise((resolve, reject) => {
    axios({
      method: type,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch(function (e) {
        if (handleError) {
          const status = e.response.status;
          let message = 'ERROR';
          let description = e.response.data.msgType ? e.response.data.msg : codeMessage[status];

          if (status === 401) {
            // if current state is sign in,
            // reset this state to sign out
            // @TODO 401 需要给出具体错误原因，
            // 1. 未登录，告知需要登录
            // 2. 过期，告知需要重新登录
            // 3. 不需要处理，默认不处理

            localStorage.clear();
            message = 'No permission';
            description = (
              <div>
                Please <Link to="/signin">Sign in</Link>, before to do this.
              </div>
            );
          }
          notification.error({
            message,
            description,
            onClick: () => {
              notification.destroy();
            },
          });
        }

        reject(e.response);
      });
  });

  return promise;
}

export default request;
