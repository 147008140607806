import React, { useCallback } from 'react';
import { useMappedState } from 'redux-react-hook';

const AuthorizedRoute = ({ children, noMatch = null }) => {
  const status = useMappedState(useCallback(({ user }) => !!user.token, []));
  const childrenRender = typeof children === 'undefined' ? null : children;
  return <>{status ? childrenRender : noMatch}</>;
};

export default AuthorizedRoute;
