import { combineReducers } from 'redux';

import userReducer from './pages/signin/reducer';
import categoryReducer from './pages/learn/reducer';

const reducers = {
  user: userReducer,
  category: categoryReducer,
};

export default combineReducers(reducers);
